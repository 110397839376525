import {
  Typography,
  Button,
  Box,
  List,
  ListItem,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import GenericAuthenticator from '../common/GenericAuthenticator';

interface Props {
  toggleVisibility: (newOpen: boolean) => () => void;
}


export const NavBarNavigation = ({ toggleVisibility }: Props) => {
  const location = useLocation().pathname;

  return (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleVisibility(false)}>
      <List>
        <ListItem>
          <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
            <Button
              variant={location === '/teams' ? 'contained' : 'outlined'}
              component={Link}
              to='/teams'
            >
              Team Resources
            </Button>
          </Typography>
        </ListItem>
        <GenericAuthenticator teams={['GSMC']} games={['league']} roles={['admin']}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={
                  location === '/teams/chart' ? 'contained' : 'outlined'
                }
                component={Link}
                to='/teams/chart'
              >
                Chart
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
        <GenericAuthenticator teams={['GSMC']} games={['league']} roles={['staff', "owner"]}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={
                  location === '/teams/gsmcScrimsDashboard' ? 'contained' : 'outlined'
                }
                component={Link}
                to='/teams/gsmcScrimsDashboard'
              >
                Scrim Dashboard
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
        <GenericAuthenticator teams={['GSMC']} games={['league']} roles={['owner', 'staff', "player"]}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={
                  location === '/teams/gridScrims' ? 'contained' : 'outlined'
                }
                component={Link}
                to='/teams/gridScrims'
              >
                Scrims
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
        <GenericAuthenticator teams={['BGZ']} games={['league']} roles={['owner', 'staff', "player"]}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={
                  location === '/teams/bgzTryouts' ? 'contained' : 'outlined'
                }
                component={Link}
                to='/teams/bgzTryouts'
              >
                BGZ Tryouts
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
        <GenericAuthenticator teams={['GSMC', 'BGZ', 'Esports']} games={['league']} roles={['staff', "player", "owner"]}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={
                  location === '/teams/gridEsports' ? 'contained' : 'outlined'
                }
                component={Link}
                to='/teams/gridEsports'
              >
                Esports
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
        <GenericAuthenticator teams={['GSMC']} games={['league']} roles={['staff', "player", "owner"]}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={
                  location === '/teams/riotScouting' ? 'contained' : 'outlined'
                }
                component={Link}
                to='/teams/riotScouting'
              >
                Scouting
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
        <GenericAuthenticator teams={['Esports']} games={['league']}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={location === '/teams/lolScouting' ? 'contained' : 'outlined'}
                component={Link}
                to='/teams/lolScouting'
              >
                LOL Scouting
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
        <GenericAuthenticator teams={['Esports']} games={['valorant']}>
          <ListItem>

            <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
              <Button
                variant={
                  location === '/teams/valCT' ? 'contained' : 'outlined'
                }
                component={Link}
                to='/teams/valCT'
              >
                VAL CT
              </Button>
            </Typography>
          </ListItem>
        </GenericAuthenticator>
      </List >
    </Box>
  )
}