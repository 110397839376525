import { useContext } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Routes, Route } from 'react-router-dom';

import { GuardedRoutes } from './components/common/GuardedRoutes';
import { NavBar } from './components/NavBar/NavBar';
import { LoadingContext } from './context/LoadingContext';

import { BGZRoflScrimsDashboard } from './pages/Dev/BGZRoflScrimsDashboard';
import { Chart } from './pages/Dev/Chart';
import { EsportsMatches } from './pages/Dev/EsportsMatches';
import { GSMCRoflScrimsDashboard } from './pages/Dev/GSMCRoflScrimsDashboard';
import { GSMCScrimsDashboard } from './pages/Dev/GSMCScrimsDashboard';
import { Home } from './pages/Main/Home';
import { LolScouting } from './pages/Dev/LolScouting';
import { RiotScouting } from './pages/Dev/RiotScouting';
import { Scrims } from './pages/Dev/Scrims';
import { TeamResources } from './pages/Dev/TeamResources';
import { ValCT } from './pages/Dev/ValCT';
import { BGZTryouts } from './pages/Dev/BGZTryouts';

import 'react-toastify/dist/ReactToastify.css';

export const AppRoutes = () => {
  const { isLoading } = useContext(LoadingContext);

  return (
    <>
      <NavBar />
      <Container maxWidth="xl">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/teams' element={<TeamResources />} />
          <Route element={<GuardedRoutes />}>
            <Route path='/teams/bgzScrims' element={<BGZRoflScrimsDashboard />} />
            <Route path='/teams/bgzTryouts' element={<BGZTryouts />} />
            <Route path='/teams/chart' element={<Chart />} />
            <Route path='/teams/gridEsports' element={<EsportsMatches />} />
            <Route path='/teams/gridScrims' element={<Scrims />} />
            <Route path='/teams/gsmcScrimsDashboard' element={<GSMCRoflScrimsDashboard />} />
            <Route path='/teams/lolScouting' element={<LolScouting />} />
            <Route path='/teams/riotScouting' element={<RiotScouting />} />
            <Route path='/teams/scrimsDashboard' element={<GSMCScrimsDashboard />} />
            <Route path='/teams/valCT' element={<ValCT />} />
          </Route>
        </Routes>
      </Container>
      {isLoading &&
        <Box sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 10000,
          margin: '36px'
        }}>
          <CircularProgress />
        </Box>
      }
      <ToastContainer />
    </>
  );
};
