import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Unstable_Grid2 as Grid,
  Typography,
} from '@mui/material';

import { getChampionIdFromName } from '../utils/riot';
import ChampionImage from './Images/ChampionImage';
import ItemImage from './Images/ItemImage';
import { Participant, ROFLSummary } from '../types/api/atlas/ROFL Summary';

interface Props {
  match: ROFLSummary;
  teamCode: string;
}

export const ROFLMatchDetails = ({ match, teamCode }: Props) => {
  const teams = [
    match.statsJson.filter((player) => {
      return player.team === '100'
    }),
    match.statsJson.filter((player) => {
      return player.team === '200'
    }),
  ]

  const getStatReduce = (team: Participant[], stat: string) => {
    let acc = 0
    // eslint-disable-next-line array-callback-return
    team.map((participant) => {
      acc = acc + Number(participant[stat as keyof Participant])
    })
    return acc
  }

  return (
    <Card key={match.fileName}>
      <CardContent>
        <Grid container>
          {teams.map((team) => {
            const isOwnTeam = team.find((participant) => {
              return participant.name.includes(teamCode)
            })?.name ? true : false
            const kills = getStatReduce(team, 'championsKilled')
            const deaths = getStatReduce(team, 'numDeaths')
            const assists = getStatReduce(team, 'assists')
            return (
              <Grid xs={6}>
                <Typography variant='h5'>{`${isOwnTeam ? 'Ally' : 'Enemy'} Team (${kills}/${deaths}/${assists})`}</Typography>
                <Grid container>
                  {team.map((player) => {
                    return (
                      <Grid xs={12} key={player.puuid}>
                        <CardHeader
                          style={{ padding: '0' }}
                          avatar={
                            <>
                              <ChampionImage
                                championId={getChampionIdFromName(player.skin)}
                              />
                            </>
                          }
                          title={
                            <Box>
                              <Typography>
                                {`${player.name} - ${player.skin} `}
                                {`${player.championsKilled}/${player.numDeaths}/${player.assists}`}
                              </Typography>
                              <Typography></Typography>
                              <Typography>
                                <ItemImage
                                  itemId={Number(player.item0)}
                                  matchDate={Number(match.gameDate)}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={Number(player.item1)}
                                  matchDate={Number(match.gameDate)}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={Number(player.item2)}
                                  matchDate={Number(match.gameDate)}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={Number(player.item3)}
                                  matchDate={Number(match.gameDate)}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={Number(player.item4)}
                                  matchDate={Number(match.gameDate)}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={Number(player.item5)}
                                  matchDate={Number(match.gameDate)}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={Number(player.item6)}
                                  matchDate={Number(match.gameDate)}
                                  size={40}
                                />
                              </Typography>
                            </Box>
                          }
                        ></CardHeader>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      <CardContent>
      </CardContent>
    </Card>
  )
};

