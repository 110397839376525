import { GAMHSummaryList } from '../../../types/api/atlas/GAMH_Summary';
import { ROFLSummaryList } from '../../../types/api/atlas/ROFL Summary';
import { ROFL2SummaryList } from '../../../types/api/atlas/ROFL2 Summary';
import { Match } from '../../../types/api/riot/match';
import { baseURL, baseHeaders } from '../../../utils/fetch';

import { customFetch } from '../api';

const atlasUrl = `${baseURL}/atlas`;

export const getGSMCScrimList = async (startTime: number, endTime?: number) => {
  return await customFetch(
    `${atlasUrl}/gsmc/scrims?startTime=${startTime}${endTime ? `&endTime=${endTime}` : ''}`,
    {
      method: 'GET',
      headers: baseHeaders,
    }
  ).then(async (data) => {
    return (await data.json() as GAMHSummaryList);
  });
};

export const getBGZRoflScrimList = async (startTime?: number, endTime?: number) => {
  return await customFetch(
    `${atlasUrl}/bgz/scrims?startTime=${startTime}${endTime ? `&endTime=${endTime}` : ''}`,
    {
      method: 'GET',
      headers: baseHeaders,
    }
  ).then(async (data) => {
    return (await data.json() as ROFLSummaryList);
  });
};

export const getBGZTryouts = async () => {
  return await customFetch(
    `${atlasUrl}/bgz/tryouts`,
    {
      method: 'GET',
      headers: baseHeaders,
    }
  ).then(async (data) => {
    return (await data.json() as ROFL2SummaryList);
  });
};

export const getGSMCRoflScrimList = async (patch: string) => {
  return await customFetch(
    `${atlasUrl}/gsmc/roflScrims?patch=${patch}`,
    {
      method: 'GET',
      headers: baseHeaders,
    }
  ).then(async (data) => {
    return (await data.json() as ROFLSummaryList);
  });
};

export const getMatchesbyPuuids = async (puuids: string, startDate: number, endDate: number) => {
  return await customFetch(
    `${atlasUrl}/riot/matchesByPuuids/${puuids}?startDate=${startDate}&endDate=${endDate}`,
    {
      method: 'GET',
      headers: baseHeaders,
    }
  ).then(async (data) => {
    return (await data.json() as Match[]);
  });
};